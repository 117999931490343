import '../styles/globals.css';
import type { FC } from 'react';
import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import PlausibleProvider from 'next-plausible';
import { StoreProvider } from '../providers/store-provider';
import { StoreSetup } from '../components/store-setup/store-setup';
import { IS_RAY } from '../utils/constants';
import { navSystem } from '@raybrowser/nav-system';
import { suisseIntl, suisseIntlMono } from '../fonts';
import { useRootScrollbarSizes } from '../hooks/use-root-scrollbar-sizes';
import { useApollo } from '../hooks/use-apollo';
import { initSidePanelAnchors } from '../utils/side-panel-anchors';

const PLAUSIBLE_DOMAIN = process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN || '';

// Add a "isRay" class to the document element if the site is being viewed in
// Ray Browser.
if (IS_RAY) {
  document.documentElement.classList.add('isRay');
  initSidePanelAnchors();
}

// Configure and start the nav system.
if (typeof window !== 'undefined') {
  navSystem.updateSettings({
    blurOnPointerInteraction: true,
    scrollEdgePadding: { left: 24, right: 24, top: IS_RAY ? 64 + 24 : 24, bottom: 24 },
    layers: [
      document.getElementById('__next') || '#__next',
      document.getElementById('modals') || '#modals',
    ],
  });
  navSystem.start();
}

export default function App({ Component, pageProps }: AppProps) {
  // Hack to get Vercel deployment working past v13.3.0. It failed due to a
  // type error: 'Component' cannot be used as a JSX component.
  const CustomComponent = Component as FC<any>;
  const apolloClient = useApollo(pageProps);

  useRootScrollbarSizes();

  return (
    <>
      <Head>
        <meta key="charset" charSet="utf8" />
        <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />
        <meta key="theme-color" name="theme-color" content="#000000" />
        <link key="icon" rel="icon" type="image/svg+xml" href="/logo-square.svg" />
        <link key="mask-icon" rel="mask-icon" href="/logo-square.svg" color="#f95e14" />
        <link
          key="apple-touch-icon"
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link key="manifest" rel="manifest" href="/site.webmanifest" />
      </Head>
      <style jsx global>{`
        :root {
          --g--font-suisse-intl: ${suisseIntl.style.fontFamily};
          --g--font-suisse-intl-mono: ${suisseIntlMono.style.fontFamily};
        }
      `}</style>
      <StoreProvider>
        <PlausibleProvider domain={PLAUSIBLE_DOMAIN} enabled={!!PLAUSIBLE_DOMAIN}>
          <ApolloProvider client={apolloClient}>
            <StoreSetup>
              <CustomComponent {...pageProps} />
            </StoreSetup>
          </ApolloProvider>
        </PlausibleProvider>
      </StoreProvider>
    </>
  );
}
