import { useEffect } from 'react';

export function useRootScrollbarSizes() {
  useEffect(() => {
    const el = document.getElementById('__next');
    if (!el) return;

    const computeSizes = () => {
      const w = el.offsetWidth - el.clientWidth;
      const h = el.offsetHeight - el.clientHeight;
      document.documentElement.style.setProperty('--g--root-scrollbar-width', `${w}px`);
      document.documentElement.style.setProperty('--g--root-scrollbar-height', `${h}px`);
    };

    computeSizes();

    window.addEventListener('resize', computeSizes);
    return () => {
      window.removeEventListener('resize', computeSizes);
      document.documentElement.style.removeProperty('--g--root-scrollbar-width');
      document.documentElement.style.removeProperty('--g--root-scrollbar-height');
    };
  }, []);
}
